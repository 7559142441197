import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import DashboardContainerV2 from '../../../components/dashbaord/dashboardContainerV2.jx';

import '../../../styles/newBlog/newBlog.css'
import LoadingIcon from '../../../components/loadingIcon';
import { authorsArray } from '../../../dummyData/authors';
import { categoriesArray } from '../../../dummyData/categories';
import { tagsArray } from '../../../dummyData/tags';
import { Cancel01Icon } from 'hugeicons-react';
import BlogCategories from '../../../components/blog/newBlog/BlogCategories';
import BlogTags from '../../../components/blog/newBlog/BlogTag';
import BlogAuthors from '../../../components/blog/newBlog/blogAuthors';
import { CSR_API_ENDPOINT, TINYEMCE_API_KEY } from '../../../utils/api';
import BlogDepartments from '../../../components/blog/newBlog/departments';

// dummy_data
const UpdateBlogPageContent = () => {
    const { blogSlug } = useParams()
    const editorRef = useRef(null);
    const [title, setTitle] = useState("")
    const [departments, setDepartments] = useState([])
    const [shortDescription, setShortDescription] = useState("")
    const [blogContent, setBlogContent] = useState("")
    const [image, setImage] = useState("")
    const [uploadingFeaturedImage, setUploadingFeaturedImage] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [savingDraft, setSavingDraft] = useState(false)
    const [successMessage, setSuccessMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    // handle featured image
    const handleFeaturedImage = async (event) => {
        const image = event.target.files[0];
        if (image) {
            try {
                setUploadingFeaturedImage(true)
                const formData = new FormData()
                formData.append('file', image)
                const response = await axios.post(`${CSR_API_ENDPOINT}/blog/upload-images/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response.status === 200) {
                    setUploadingFeaturedImage(false)
                    setImage(response.data.urls)
                }
            } catch (error) {
                setUploadingFeaturedImage(false)
                console.log(error)
            }
        }
    }

    // tags
    const [blogTags, setBlogTags] = useState([])

    // categories
    const [blogCategories, setBlogCategories] = useState([])

    // author
    const [blogAuthors, setBlogAuthors] = useState()

    const handleEditorInit = (evt, editor) => {
        editorRef.current = editor;
    };

    const handleEditorChange = (content, editor) => {
        setBlogContent(content);
    };
    const images_upload_handler = async (blobInfo, progress) => {
        try {
            const formData = new FormData();
            formData.append('file', blobInfo.blob(), blobInfo.filename());
            formData.append('folder', 'your-folder-name');

            const response = await axios.post(
                `${CSR_API_ENDPOINT}/blog/upload-images/`,
                formData,

            );
            console.log('image upload response: ', response);
            return response.data.urls;
        } catch (error) {
            throw new Error('Image upload failed: ' + error.message);
        }
    };

    useEffect(() => {
        const fetchBlogData = async () => {
            try {
                setIsLoading(true)
                const response = await axios.get(`${CSR_API_ENDPOINT}/blog/details/${blogSlug}/`)
                console.log(response.data)
                if (response.status = 200) {
                    setIsLoading(false)
                    setTitle(response.data.title)
                    setShortDescription(response.data.short_description)
                    setBlogContent(response.data.content)
                    setImage(response.data.featured_image)
                    setBlogTags(response.data.tags)
                    setBlogCategories(response.data.categories)
                    setBlogAuthors(response.data.authors)

                }
            } catch (error) {
                setIsLoading(false)
                setErrorMessage("Error getting blog data: " + error.message)
            }
        }
        fetchBlogData()
    }, [])

    const handleBlogUpdate = async () => {

        const blogData = {
            "title": title,
            "content": blogContent,
            "featured_image": image,
            "authors": blogAuthors?.map(author => author),
            "short_description": shortDescription,
            "categories": blogCategories?.map(cat => cat),
            "tags": blogTags?.map(tag => tag),
            "departments": departments.map(department => ({
                "name": department.value
            }))
        }
        console.log('blog', blogData)
        if (!title || !blogAuthors || !blogCategories || !blogTags || !blogContent) {
            setErrorMessage('All fields are required')
            return
        }
        localStorage.setItem('blogContent', JSON.stringify(blogData))

        try {
            setIsUpdating(true)
            console.log(blogData)
            const response = await axios.post(`${CSR_API_ENDPOINT}/blog/${blogSlug}/update/`, blogData)
            if (response.status === 200) {
                setSuccessMessage("Blog has been updated successfully")
                localStorage.removeItem('blogContent')
                setIsUpdating(false)
                setTimeout(() => {
                    window.location.href = '/v2/blog/recent-blogs/'
                }, 2000);
            }
        } catch (error) {
            setIsUpdating(false)
            if (error.response.status === 400) {
                setErrorMessage(error.response.data.error)
            } else {
                setErrorMessage("Failed to create blog, contact your administrator")
            }
        }
    }

    const handleSaveDraft = () => {

        setSuccessMessage("Your draft has been saved")
        setSavingDraft(true)
        setTimeout(() => { setSavingDraft(false) }, 2000)
    }
    useEffect(() => {
        setTimeout(() => {
            setErrorMessage("")
            setSuccessMessage("")
        }, 15000)
    })

    return (
        <div className='new-blog-page'>
            <h2>Update blog</h2>
            {
                successMessage && <div className="success-message">{successMessage}</div>
            }
            {
                errorMessage && <div className="error-message">{errorMessage}</div>
            }
            {
                isLoading ? ''
                    :
                    <div className="form">
                        <div className="main-content">
                            <div className="input">
                                <label htmlFor="blogTitle">Blog title</label>
                                <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" placeholder='Blog title' />
                            </div>
                            <div className="input">
                                <label htmlFor="blogShortDescription">Short description</label>
                                <textarea value={shortDescription} onChange={(e) => setShortDescription(e.target.value)} placeholder='Blog short description' rows={5}></textarea>
                            </div>
                            <Editor
                                apiKey={TINYEMCE_API_KEY}
                                onInit={handleEditorInit}
                                value={blogContent}

                                init={{
                                    height: 500,
                                    plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                        'insertdatetime', 'media', 'table', 'help', 'wordcount'
                                    ],
                                    toolbar: 'undo redo | blocks | bold italic backcolor | alignleft aligncenter alignright alignjustify | image | removeformat | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',

                                    images_upload_handler: images_upload_handler
                                }}
                                onEditorChange={handleEditorChange}
                            />
                        </div>
                        <div className="extra-content">
                            <div className="actions">
                                <button onClick={handleBlogUpdate} className="primary-button">
                                    {
                                        isUpdating ?
                                            <LoadingIcon size={18} />
                                            : 'Update'
                                    }
                                </button>
                                <button onClick={handleSaveDraft} className="secondary-button">
                                    {
                                        savingDraft ?
                                            <LoadingIcon size={18} />
                                            : 'Change to draft'
                                    }
                                </button>
                            </div>
                            <div className="blog-featured-image">
                                <label htmlFor="blogFeaturedImage">Featured image</label>
                                <div className="featured-image-placeholder">
                                    {
                                        image && <img src={image}></img>
                                    }
                                    {
                                        uploadingFeaturedImage ?
                                            <LoadingIcon size={18} />
                                            : ''
                                    }
                                    <input onChange={(event) => handleFeaturedImage(event)} type="file" id="blogFeaturedImage" name="blogFeaturedImage" accept="image/*" />
                                </div>
                                <img src="" alt="" />
                            </div>

                            <BlogDepartments setBlogDepartments={setDepartments} blogDepartments={departments} />
                            <BlogAuthors setBlogAuthors={setBlogAuthors} blogAuthors={blogAuthors} />
                            <BlogCategories setBlogCategories={setBlogCategories} blogCategories={blogCategories} />
                            <BlogTags setBlogTags={setBlogTags} blogTags={blogTags} />

                        </div>

                    </div>
            }

        </div>
    );
};



const UpdateBlogPage = () => {
    return (
        <div>
            <DashboardContainerV2 content={<UpdateBlogPageContent />} />
        </div>
    );
};

export default UpdateBlogPage;

// create a contact form component
