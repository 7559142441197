import React, { useState, useEffect } from "react";
import { Cancel01Icon } from "hugeicons-react";
import { getNameInitials } from "./blogAuthors";

const departmentsChoices = [
    { label: "Comprehensive Staffing Services", value: "Comprehensive Staffing Services" },
    { label: "Training Center", value: "Training Center" },
    { label: "Software Development", value: "Software Development" },
    { label: "Sales And Marketing", value: "Sales And Marketing" },
    { label: "Tech Support", value: "Tech Support" },
    { label: "Accounting And Finance", value: "Accounting And Finance" }
];

const BlogDepartments = ({ setBlogDepartments, blogDepartments }) => {
    const [selectedItems, setSelectedItems] = useState(blogDepartments || []);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [searchString, setSearchString] = useState('');

    const handleSearches = (value) => {
        setSearchString(value);
        setShowSuggestions(true);
        const filteredDepartments = departmentsChoices.filter(dept =>
            dept.label.toLowerCase().includes(value.toLowerCase())
        );
        setSuggestions(filteredDepartments);
    };

    const handleSelectedItem = (department) => {
        if (!selectedItems.some(item => item.value === department.value)) {
            setSelectedItems(prev => [...prev, department]);
        }
        setShowSuggestions(false);
        setSearchString('');
    };

    const handleRemoveSelectedItem = (itemToRemove) => {
        setSelectedItems(prev => prev.filter(item => item.value !== itemToRemove.value));
    };

    useEffect(() => {
        setBlogDepartments(selectedItems);
    }, [selectedItems, setBlogDepartments]);

    return (
        <div className={`${showSuggestions ? 'popup-selection' : 'input'}`}>
            <div className="action">
                <label htmlFor="department-search">Departments</label>
                <div className="view-all"></div>
            </div>
            <input
                id="department-search"
                type="search"
                value={searchString}
                onChange={(e) => handleSearches(e.target.value)}
                placeholder="Search departments"
            />
            <div className="department-list">
                {selectedItems.length > 0 ? (
                    selectedItems.map((department, index) => (
                        <div key={index} className="department">
                            <p>{getNameInitials(`${department.label.split()}`)}</p>
                            <div className="remove-item">
                                <Cancel01Icon
                                    size={14}
                                    onClick={() => handleRemoveSelectedItem(department)}
                                />
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="select-item">No department selected</div>
                )}

                {showSuggestions && suggestions.length > 0 && (
                    <div className="suggestions">
                        {suggestions.map((suggestion, index) => (
                            <div
                                className="suggestion"
                                key={index}
                                onClick={() => handleSelectedItem(suggestion)}
                            >
                                <p>{suggestion.label}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default BlogDepartments;