import axios from "axios";
export const trainingCenterAPI = 'https://csr-training-center-backend.onrender.com/api'
const trainingCenter = axios.create({
    baseURL: trainingCenterAPI
})

trainingCenter.interceptors.request.use(
    (config) => {
        const accessToken = sessionStorage.getItem("trainingCenterAccessToken");
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`
        } return config
    }, (error) => {
        return Promise.reject(error)
    }
)
trainingCenter.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {

        return Promise.reject(error);
    }
);
export default trainingCenter