import React from 'react';
import CustomSelectInput from '../../../components/customSelectInput';
import ExportExcel from '../../../utils/exportExcel';
import "../../../styles/components/trainingcohortoverview.css";

const CohortSearch = ({
    searchString,
    setSearchString,
    selectedDepartment,
    setSelectedDepartment,
    sortOptions,
    setSortOptions,
    filteredApplications
}) => {

    return (
        <div className="actions-filters">
            <div className='search-header'>
                <h1>Applicants</h1>
                <p><span>{filteredApplications && filteredApplications.length}</span>New Applicants</p>
            </div>
            <div className="search-input">
                <input
                    type="search"
                    placeholder="Search by name, email, or phone"
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                />
            </div>
            <div className="filters">
                <CustomSelectInput
                    options={["Software Dev. Department", "Graphic Design", "Accounting", "Digital marketing", "Tech support", "Customer care", "Soft skills"]}
                    selected={selectedDepartment}
                    placeholder={'department'}
                    setSelected={setSelectedDepartment}
                />
                <CustomSelectInput
                    options={["Name A-Z", "Date Applied"]}
                    placeholder={'date'}
                    selected={sortOptions}
                    setSelected={setSortOptions}
                />
                {/* <ExportExcel data={filteredApplications} columnsToExport={['id', 'first_name']} /> */}
            </div>
        </div>
    );
}

export default CohortSearch;
