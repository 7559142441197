import React, { useEffect, useState } from 'react'
import DashboardContainerV2 from '../../../components/dashbaord/dashboardContainerV2.jx'
import { Delete01Icon, EyeIcon, Loading01Icon, Loading02Icon, PencilEdit01Icon, Share01Icon } from 'hugeicons-react'
import axios from 'axios'
import { CSR_API_ENDPOINT } from '../../../utils/api'

import '../../../styles/recentBlogs/recentBlogs.css'
import { slicedText } from '../../../utils/slicedText'
import { getNameInitials } from '../../../components/blog/newBlog/blogAuthors'
import { formattedDateTime } from '../../../utils/formatedDateTime'
import { Link } from 'react-router-dom'
import DeleteBlogForm from '../../../components/forms/blog/deleteBlogForm'


const BlogCard = ({ blog, index }) => {
    const permissions = localStorage.getItem('permissions') ? JSON.parse(localStorage.getItem('permissions')) : []
    const canModifyBlog = permissions.includes('Director')

    const [showDeletePopup, setShowDeletePopup] = useState(false)
    const handleDeletePopup = () => {
        setShowDeletePopup(!showDeletePopup)
    }
    return (
        <>
            {
                showDeletePopup
                    ? <div className="delete-blog-popup">
                        <DeleteBlogForm blog={blog} handleDeletePopup={handleDeletePopup} />
                        <div />
                    </div>
                    : ''
            }
            <div key={index} className="blog-card">
                {
                    blog.featured_image
                        ? <div className="featured-image">
                            <img src={blog.featured_image} alt={blog.title} />
                        </div>
                        : <div className="image-placeholder">

                        </div>
                }
                <div className="blog-info">
                    <h3 className='blog-title'>{slicedText(blog.title, 60)}</h3>
                    <div className="blog-meta">
                        <small className="date">On:  {formattedDateTime(blog.date_created)}</small>
                        <div className="authors-container">
                            By:
                            <div className="authors">{
                                blog.authors && blog.authors.map((author, index) => (
                                    blog.authors.length > 1 ?

                                        <div className='author'><p>{getNameInitials(`${author.first_name} ${author.last_name}`)}</p></div>
                                        :

                                        <div className='author-name' key={index}><p>{author.first_name} {author.last_name}</p></div>
                                ))
                            }</div>
                        </div>
                    </div>
                    <p>{slicedText(blog.short_description, 120)}</p>
                    <div className="actions">
                        {
                            canModifyBlog ?
                                <>
                                    <div onClick={handleDeletePopup} className='delete action'>
                                        <Delete01Icon size={18} />
                                        Delete
                                    </div>
                                    <Link to={`/v2/blog/${blog.slug}/update/`} className='edit action'>
                                        <PencilEdit01Icon size={18} />
                                        Edit
                                    </Link>
                                    <div className='view action'>
                                        <EyeIcon size={18} />
                                        View
                                    </div>
                                    {/* <div className='share action' >
                                    <Share01Icon size={18} />
                                    Share
                                </div> */}
                                </>
                                : <>
                                    <div className='view action'>
                                        <EyeIcon size={18} />
                                        View
                                    </div>
                                    {/* <div className='share action' >
                                    <Share01Icon size={18} />
                                    Share
                                </div> */}
                                </>
                        }
                    </div>
                </div>
            </div >
        </>
    )
}
const RecentBlogsPageContent = () => {

    const [recentBlogs, setRecentBlogs] = useState([])
    const [gettingBlogs, setGettingBlogs] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        const fetchRecentBlogs = async () => {
            try {
                setGettingBlogs(true)
                const response = await axios.get(`${CSR_API_ENDPOINT}/blog/`)
                console.log(response)
                console.log('blogs: ', response.data)
                if (response.status === 200) {
                    setRecentBlogs(response.data)
                    setGettingBlogs(false)
                }
            } catch (error) {
                setGettingBlogs(false)
                if (error.response) {
                    setError(error.response.data.error || 'Error getting recent blogs')
                } else {
                    setError('Unknown error getting recent blogs')
                }
            }
        }
        fetchRecentBlogs()
    }, [])
    return (
        <>
            <div className="recent-blogs-page">
                <h2>Recent blogs</h2>

                {
                    error && <div className="error-message">{error}</div>
                }
                <div className="recent-blogs-content">
                    {
                        gettingBlogs ? <><Loading02Icon className='loading-icon' /> Getting recent blogs</>
                            : <div className="recent-blogs">
                                {
                                    recentBlogs && recentBlogs.length > 0 ?
                                        recentBlogs.map((blog, index) => (
                                            <BlogCard blog={blog} index={index} />
                                        )) : 'No recent blogs available'
                                }
                            </div>
                    }
                </div>
            </div>
        </>
    )
}
const RecentBlogsPage = () => {
    return (
        <DashboardContainerV2 content={<RecentBlogsPageContent />} />
    )
}

export default RecentBlogsPage
