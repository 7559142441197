import React, { useEffect, useState } from 'react'
import DashboardContainerV2 from "../../../../components/dashbaord/dashboardContainerV2.jx";
import CohortCard from '../../../../components/trainingCenter/cohortCard';
import { Link } from 'react-router-dom'
import CohortPageLoader from './cohortsPageLoader';
import api, { API_URL } from '../../../../utils/api'
import trainingCenter, { trainingCenterAPI } from '../../../../utils/trainingCenter';


const CohortPageContent = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [cohorts, setCohorts] = useState([])

    // fetch cohorts from the server "api/training-center/cohorts/"

    useEffect(() => {
        const fetchCohorts = async () => {
            try {
                const response = await api.get(`${API_URL}/api/training-center/cohorts/`)
                if (response.status === 200) {
                    setCohorts(response.data)
                    console.log(response.data)
                    setIsLoading(false)
                }
            } catch (error) {
                console.error(error)
                setIsLoading(false)
            }
        }
        fetchCohorts()
    }, [])


    return isLoading ? <CohortPageLoader /> : (
        <div className="cohorts-archive">
            {/* breadcrumbs */}
            {/* end of breadcrumbs */}

            {/* page header */}
            {/* end of page header */}

            {/* cohorts */}
            {/* map the cohort array */}


            {cohorts.map((cohort, index) => (
                <Link Link to={`/v2/training-center/cohorts/${cohort.id}/`} key={index} className="cohort-card">

                    <CohortCard key={cohort.id} cohort={cohort} />

                </Link>
            ))}
            {/* end of cohorts */}

        </div>
    )
}


const CohortPage = () => {
    return (
        <DashboardContainerV2 content={<CohortPageContent />} />
    )
}

export default CohortPage


// import React, { useEffect, useState } from 'react';
// import DashboardContainerV2 from "../../../../components/dashbaord/dashboardContainerV2.jx";
// import CohortCard from '../../../../components/trainingCenter/cohortCard';
// import CohortPageLoader from './cohortsPageLoader';
// import api, { API_URL } from '../../../../utils/api';

// const CohortPageContent = () => {
//     const [isLoading, setIsLoading] = useState(true);
//     const [cohorts, setCohorts] = useState([]);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         const fetchCohorts = async () => {
//             try {
//                 const response = await api.get(`${API_URL}/api/training-center/cohorts/`);
//                 if (response.status === 200) {
//                     setCohorts(response.data);
//                     setIsLoading(false);
//                 } else {
//                     console.error(`Error fetching cohorts: ${response.status}`);
//                     setError(`Error fetching cohorts: ${response.status}`);
//                     setIsLoading(false);
//                 }
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//                 setError('Error fetching data');
//                 setIsLoading(false);
//             }
//         };
//         fetchCohorts();
//     }, []);

//     if (isLoading) {
//         return <CohortPageLoader />;
//     }

//     if (error) {
//         return <div className="error-message">{error}</div>;
//     }

//     return (
//         <div className="cohorts-archive">
//             {/* breadcrumbs */}
//             {/* end of breadcrumbs */}

//             {/* page header */}
//             {/* end of page header */}

//             {/* cohorts */}
//             {cohorts.map((cohort) => (
//                 <CohortCard key={cohort.id} cohort={cohort} />
//             ))}
//             {/* end of cohorts */}
//         </div>
//     );
// };

// const CohortPage = () => {
//     return (
//         <DashboardContainerV2 content={<CohortPageContent />} />
//     );
// };

// export default CohortPage;